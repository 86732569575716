<template>
	<figure
		v-if="quote || origin"
		:id="$vnode.key"
		class="c-block-list-quote-custom"
	>
		<blockquote v-if="quote" class="relative">
			<SvgQuotationMarks
				class="
					u-color-theme-transition
					text-secondary
					w-95
					h-66
					absolute
					left-0
					-top-24
					>=1024:-top-12
				"
			/>
			<p
				class="relative font-darker-grotesque text-quote pl-lg"
				v-text="quote"
			></p>
		</blockquote>
		<figcaption
			v-if="origin"
			class="pl-lg mt-lg"
			v-text="origin"
		></figcaption>
	</figure>
</template>

<script>
import SvgQuotationMarks from '~/assets/svgs/quotation-marks.svg?inline';

export default {
	name: 'BlockListQuoteCustom',

	components: {
		SvgQuotationMarks,
	},

	inheritAttrs: false,

	props: {
		quote: String,
		origin: String,
	},
};
</script>
